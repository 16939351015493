import React, { useEffect, useState } from "react";
import Button from "components/Button";
import { useAuth } from "hooks/Session/auth";
import { Session } from "hooks/Utils/Session";
import { tmpGetChain } from "hooks/Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Duplicate } from "components/Section/Design/ModalComponents/Duplicate";
import { useModal } from "components/Modal";
import { DeleteDesign, ShowProjectsInUse } from "components/Section/Design/ModalComponents/DeleteDesign";
import { Unapply } from "components/Section/Design/ModalComponents/UnApply";
import { CHAIN, GET_DESIGNS_INFO } from "./utils/designsQueries";
import { ApplyColumn } from "components/Section/Design/ModalComponents/ApplyColumn";
import { EditDesigns } from "components/Section/Design/ModalComponents/EditDesigns";
import { Properties } from "components/Section/Design/ModalComponents/Properties";

export const useDesigns = () => {
    const { t } = useTranslation();
    const { open } = useModal();
    const [designsItems, setDesignsItems] = useState([]);
    const { isCorporate } = useAuth();

    const [getDesign, { data: designs, loading: designLoading, refetch }] = useLazyQuery(GET_DESIGNS_INFO, {
        pollInterval: 60000,
    });

    const [getChain, { data: chainData, loading: chainLoading }] = useLazyQuery(CHAIN, {
        pollInterval: 60000,
    });

    useEffect(() => {
        if (designs && !designLoading) {
            Session.setSessionProp("designList", JSON.stringify(designs?.designs?.results));
            const brands = designs?.brands?.results;
            const designsItems = getDesignModels(designs, t, open, refetch, isCorporate);
            setDesignsItems(designsItems);

            if (brands) {
                Session.setSessionProp("brands", brands);
            }
        }
    }, [designs, chainData]);

    return {
        designToEdit: designs?.designs?.results,
        designsItems,
        itemsLoading: designLoading || chainLoading,
        getDesign,
        getChain,
        chainData,
        refetch,
    };
};

const getDesignModels = (designs, t, open, refetch, isCorporate) => {
    const designList = designs?.designs?.results;
    const designsLocalAssigned = designs?.designsLocalAssigned?.results;

    return designList.map((design) => designModel(design, designsLocalAssigned, t, open, refetch, isCorporate));
};

const designModel = (design, designsLocalAssigned, t, open, refetch, isCorporate) => {
    const { creatorProject, lastUpdatedTime, id, name, type, projectsInUse, lastUpdatedUserInfo } = design;

    const chain = <div className="px-2 py-1 bg-purple-600 rounded text-white">{tmpGetChain()?.name}</div>;

    const projectName = (
        <div className="px-2 py-1 bg-teal-500 rounded text-white">
            {creatorProject?.name || Session.getProject()?.name || ""}
        </div>
    );

    const lastUpdatedTimeDate = new window.ZDate(lastUpdatedTime)?.toLocaleString() || "";

    const designName = (
        <div alt={name}>
            <Button id={`design-${id}`} className="t-link font-bold" to={`/design/${id}`}>
                {name}
            </Button>
        </div>
    );

    const properties = (
        <Button
            id="show-properties"
            className="t-link font-bold cursor-pointer"
            onClick={() => open(<Properties projectsInUse={projectsInUse} />)}
        >
            {projectsInUse?.length && projectsInUse?.length === 1
                ? `${projectsInUse?.length} ${t("property")}`
                : `${projectsInUse?.length} ${t("properties")}`}
        </Button>
    );

    return {
        id,
        design: designName,
        author: lastUpdatedUserInfo?.name || "",
        "last-update": lastUpdatedTimeDate || "",
        creator: type === "LOCAL" ? projectName : chain,
        properties,
        apply: <ApplyColumn refetch={refetch} design={design} designsLocalAssigned={designsLocalAssigned} />,
        filterType: type,
        rowConfig: {
            actions: getActions(design, t, open, refetch, isCorporate, designsLocalAssigned),
        },
    };
};

const getActions = (design, t, open, refetch, isCorporate, designsLocalAssigned) => {
    const { isAssigned, type, id, name, projectsInUse, isAssignedFromCorporate } = design;

    const isAssignedInProject = projectsInUse?.filter((project) => project?.isAssigned && !project?.hasDefaultDesign);
    const disabledUnapply = isAssignedFromCorporate && type === "CORPORATE" && !designsLocalAssigned?.length;

    const editDesign = {
        id: `edit-design`,
        label: t("edit-design"),
        onClick: () =>
            isCorporate
                ? (window.location.href = `#/design/edit-design/${id}`)
                : open(<EditDesigns design={design} refetch={refetch} />),
    };

    const editScreen = {
        id: `edit-screen-design`,
        label: t("edit-screen"),
        onClick: () => (window.location.href = `#/design/${id}/`),
    };

    const duplicateDesign = {
        id: `duplicate-design`,
        label: t("duplicate-design"),
        onClick: () => open(<Duplicate designName={name} id={id} refetch={refetch} />),
    };

    const deleteDesign = {
        id: `delete-design`,
        label: t("delete-design"),
        onClick: () =>
            open(
                isAssignedInProject?.length ? (
                    <ShowProjectsInUse isAssignedInProject={isAssignedInProject} designName={name} />
                ) : (
                    <DeleteDesign designName={name} id={id} refetch={refetch} projectsInUse={projectsInUse} />
                )
            ),
    };

    const unassignDesign = {
        id: `unapply-design`,
        label: t("unapply-design"),
        tooltip: disabledUnapply ? t("The design is applied. It cannot be deleted.") : undefined,
        disabled: disabledUnapply,
        onClick: () => open(<Unapply designName={name} id={id} refetch={refetch} />),
    };

    const viewScreens = {
        id: `view-screen-design`,
        label: t("see-screens"),
        onClick: () => (window.location.href = `#/design/${id}/`),
    };

    const actionsInGlobalOrInLocalNotApplied = [editDesign, editScreen, duplicateDesign, deleteDesign];
    const actionsInLocalWhenAssigned = [editDesign, editScreen, unassignDesign, duplicateDesign, deleteDesign];
    const actionsInLocalByGlobalDesigns = [viewScreens, duplicateDesign];
    const actionsInLocalByGlobalDesignApplied = [...actionsInLocalByGlobalDesigns, unassignDesign];

    if (isCorporate) {
        return actionsInGlobalOrInLocalNotApplied;
    }
    if (isAssigned) {
        return actionsInLocalWhenAssigned;
    }
    if (isAssignedFromCorporate && type === "CORPORATE" && !designsLocalAssigned?.length) {
        return actionsInLocalByGlobalDesignApplied;
    }
    if (type === "CORPORATE") {
        return actionsInLocalByGlobalDesigns;
    }
    return actionsInGlobalOrInLocalNotApplied;
};
