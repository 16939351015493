import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../useSectionHeader";
import SettingsCard from "components/SettingsCard";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import Button from "components/Button";
import Select from "components/Select";
import { useThemes } from "./ModalComponents/AddDesigns";
import Table from "components/ZafiroTable";
import Modal, { useModal } from "components/Modal";
import { useParams } from "react-router-dom";
import { useDesigns } from "hooks/GraphqlCalls/Design/useDesigns";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";
import { tmpGetChain } from "hooks/Utils/Utils";

export const EditDesign = () => {
    const { designToEdit, itemsLoading, getDesign } = useDesigns();
    const design = designToEdit?.[0];
    const { open, close } = useModal();
    const { id } = useParams();
    const { t } = useTranslation();
    const [changeName, setChangeName] = useState(false);
    const [changeDefaultAppearance, setChangeDefaultAppearance] = useState(false);
    const [temporalNameState, setTemporalNameState] = useState("");
    const [name, setName] = useState(designToEdit?.[0]?.name);

    const [page, setPage] = useState(1);

    const brands = JSON.parse(Session.getSessionProp("brands"));

    const perPage = 5;

    const [temporalAppearanceState, setTemporalAppearanceState] = useState("");
    const [defaultAppearance, setDefaultAppearance] = useState("");
    const [defaultAppearanceID, setDefaultAppearanceID] = useState("");
    const [manageProperties, setManageProperties] = useState(false);

    const [rows, setRows] = useState([]);

    const { themesData, getDesignsTheme, selectProperties } = useThemes();

    useEffect(() => {
        getDesignsTheme();
        getDesign({ variables: { id } });
    }, []);

    const addRow = (newRow) => {
        setRows([...rows, newRow]);
    };

    useEffect(() => {
        if (!manageProperties) {
            setRows([]);
        }
    }, [manageProperties]);

    useEffect(() => {
        setName(design?.name);
        setDefaultAppearanceID(design?.themeID);
        setRows(
            design?.projectsInUse?.map((project) => {
                return {
                    properties: project?.name,
                    status: project?.isAssigned ? (
                        <span className="text-green-100">{t("applied")}</span>
                    ) : (
                        <span>{t("not-applied")}</span>
                    ),
                    statusText: project?.isAssigned ? "applicated" : "not-applied",
                    rowConfig: {
                        actions: [
                            {
                                id: "make-default",
                                label: t("make-default"),
                                onClick: () =>
                                    open(
                                        <MakeDefaultModal
                                            name={name}
                                            propertyName={project?.name}
                                            mainParagraph={t("set-as-default-design", {
                                                name,
                                                propertyName: project?.name,
                                            })}
                                        />
                                    ),
                            },
                        ],
                    },
                };
            })
        );
    }, [design, name]);
    useEffect(() => {
        if (themesData && design) {
            setDefaultAppearance(
                themesData?.flatMap((theme) =>
                    theme?.options?.filter((option) => option?.value === design?.themeID)
                )?.[0]?.label
            );
        }
    }, [themesData, design]);

    if (!designToEdit && !design) {
        return <Loading adjust="section-loading" style={{ height: "50vh" }} />;
    }

    return (
        <>
            <UseSectionHeader title={name} navToPreviousPage={true} />
            <div className="grid grid-cols-2 gap-8">
                <SettingsCard
                    title={t("name")}
                    description={t("Write the name of the design")}
                    {...(!changeName ? { edit: { onClick: () => setChangeName(true) } } : {})}
                >
                    {changeName ? (
                        <>
                            <TextInput value={name} onChange={(value) => setTemporalNameState(value)} />
                            <div className="flex justify-end gap-4 mt-6">
                                <Button
                                    id="cancel"
                                    className="font-bold"
                                    design="white"
                                    onClick={() => {
                                        setChangeName(false);
                                    }}
                                >
                                    {t("cancel")}
                                </Button>
                                <Button
                                    id="save"
                                    design="blue"
                                    onClick={() => {
                                        setName(temporalNameState);
                                        setChangeName(false);
                                    }}
                                >
                                    {t("save")}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <p className="py-3 font-bold">{name}</p>
                    )}
                </SettingsCard>
                <SettingsCard
                    title={t("default appearance")}
                    description={t("default appearance instructions")}
                    {...(!changeDefaultAppearance ? { edit: { onClick: () => setChangeDefaultAppearance(true) } } : {})}
                >
                    {changeDefaultAppearance ? (
                        <>
                            <Select
                                id="defaultAppearance"
                                options={themesData}
                                allowUnselect={false}
                                value={defaultAppearanceID}
                                onChange={(value) => {
                                    const theme = themesData
                                        .map((theme) => theme.options.filter((option) => option.value === value))
                                        .flat(1)?.[0]?.label;
                                    setDefaultAppearanceID(value);
                                    setTemporalAppearanceState(theme || "");
                                }}
                            />
                            <div className="flex justify-end gap-4 mt-6">
                                <Button
                                    id="cancel"
                                    className="font-bold"
                                    design="white"
                                    onClick={() => setChangeDefaultAppearance(false)}
                                >
                                    {t("cancel")}
                                </Button>
                                <Button
                                    id="save"
                                    design="blue"
                                    onClick={() => {
                                        setDefaultAppearance(temporalAppearanceState);
                                        setChangeDefaultAppearance(false);
                                    }}
                                >
                                    {t("save")}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <p className="py-3 font-bold">{defaultAppearance}</p>
                    )}
                </SettingsCard>
                <SettingsCard
                    className="bg-white col-span-2 "
                    title={t("properties")}
                    description={t("Manage the properties that will have access to the design in order to apply it")}
                >
                    <Table
                        id="manage-properties"
                        className="mt-6"
                        paginate={"lazy"}
                        perPage={perPage}
                        setPage={setPage}
                        cols={["properties", "status", "actions"]}
                        topRightCorner={
                            <div className="flex gap-4 items-center">
                                <Button
                                    id="make-all-default"
                                    design="white"
                                    disabled={rows?.length === 0}
                                    className="font-semibold text-gray-700"
                                    onClick={() => {
                                        open(
                                            <Modal
                                                title={`${name} - ${t("make-all-default")}`}
                                                footer={
                                                    <div className="flex justify-end gap-4 mt-6">
                                                        <Button
                                                            id="cancel"
                                                            className="font-bold"
                                                            design="white"
                                                            onClick={close}
                                                        >
                                                            {t("cancel")}
                                                        </Button>
                                                        <Button
                                                            id="save"
                                                            design="blue"
                                                            onClick={() => {
                                                                close();
                                                            }}
                                                        >
                                                            {t("make-all-default")}
                                                        </Button>
                                                    </div>
                                                }
                                            >
                                                <p className="text-gray-900">
                                                    {t("set-as-default-for-all-properties", {
                                                        name,
                                                    })}
                                                </p>
                                            </Modal>
                                        );
                                    }}
                                >
                                    {t("make-all-default")}
                                </Button>
                                <Button
                                    id="manage-properties"
                                    design="blue"
                                    onClick={() =>
                                        open(
                                            <ManageProperties
                                                designName={name}
                                                selectProperties={selectProperties}
                                                addRow={addRow}
                                            />
                                        )
                                    }
                                >
                                    {t("Manage properties")}
                                </Button>
                            </div>
                        }
                        search={true}
                        showCount={true}
                        header={{
                            properties: { title: t("properties"), sortable: true, width: "50%" },
                            status: { title: t("status"), sortable: true, width: "25%" },
                            actions: { title: "" },
                        }}
                        rows={rows}
                        filters={[
                            {
                                id: "status",
                                title: t("status"),
                                allowUnselect: false,
                                options: [
                                    { id: "all", label: `-- ${t("all")} --`, value: "" },
                                    { id: "not-applied", label: t("not-applied"), value: "not-applied" },
                                    { id: "applied", label: t("applied"), value: "applicated" },
                                ],
                                onFilter: (value, row) => row?.statusText.includes(value),
                            },
                        ]}
                        batchActions={{
                            options: [
                                {
                                    id: "make-default",
                                    label: t("make-default"),
                                    value: "make-default",
                                },
                            ],
                            onChange: (action, rows) => {
                                if (action === "make-default") {
                                    open(
                                        <MakeDefaultModal
                                            name={name}
                                            mainParagraph={t("set-as-default-selected-properties", {
                                                name,
                                            })}
                                            secondaryParagraph={t(
                                                "Please note that if some properties have a different default design, it will be replaced with the new one"
                                            )}
                                        />
                                    );
                                }
                            },
                        }}
                    />
                </SettingsCard>
            </div>
        </>
    );
};

const MakeDefaultModal = ({ name, mainParagraph, secondaryParagraph }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    return (
        <Modal
            id="make-default"
            title={`${name} - ${t("make-default")}`}
            className="p-10 w-1/4"
            footer={
                <div className="flex justify-end gap-4 mt-6">
                    <Button id="cancel" className="font-bold" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="save"
                        design="blue"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("make-default")}
                    </Button>
                </div>
            }
            onKeyPress={{ Enter: close }}
        >
            {mainParagraph && <p>{mainParagraph}</p>}
            {secondaryParagraph && <p className="mt-6">{secondaryParagraph}</p>}
        </Modal>
    );
};

const ManageProperties = ({ designName, selectProperties, addRow }) => {
    const [selectedProperties, setSelectedProperties] = useState([]);
    const { t } = useTranslation();
    const { close } = useModal();

    const handleSelectProperties = () => {
        const selectedProperty = selectProperties?.find((property) =>
            property.options?.find((option) => option.value === selectedProperties)
        );
        const propertyName = selectedProperty?.options?.find((option) => option.value === selectedProperties)?.label;

        const newRow = {
            properties: propertyName,
            status: t("Not applied"),
            statusText: "not-applied",
            rowConfig: {
                actions: [
                    {
                        id: "make-default",
                        label: t("make-default"),
                        onClick: () => console.log("Apply"),
                    },
                ],
            },
        };
        addRow(newRow);
    };

    return (
        <Modal
            title={`${designName} - ${t("manage-properties")}`}
            footer={
                <div className="flex justify-end gap-4 mt-6">
                    <Button id="cancel" className="font-bold" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="save"
                        design="blue"
                        onClick={() => {
                            handleSelectProperties();
                            close();
                        }}
                    >
                        {t("save")}
                    </Button>
                </div>
            }
        >
            <p className="text-gray-900 mb-4">{t("design-properties-instructions")}</p>
            <Select
                id="properties"
                placeholder={t("select-properties")}
                options={selectProperties}
                onChange={(value) => setSelectedProperties(value)}
            />
        </Modal>
    );
};
