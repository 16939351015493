import { gql } from "apollo-boost";

export const GET_DESIGNS_INFO = gql`
    query Designs($id: Int64) {
        designs(size: 10000, orderBy: { field: "name", criteria: "asc" }, filter: { id: $id }) {
            info {
                count
            }
            results {
                projectsInUse {
                    assignedDate
                    isAssigned
                    hasChainModule
                    hasDefaultDesign
                    name
                    ref
                    type
                }
                creatorProject {
                    assignedDate
                    isAssigned
                    name
                    ref
                    type
                }
                lastUpdatedUserInfo {
                    name
                    ref
                }
                creatorProject {
                    name
                }
                isAssignedFromCorporate
                author
                id
                isAssigned
                isEnabled
                lastUpdatedTime
                lastUpdatedUserInfo {
                    name
                    ref
                }
                name
                parentDesignID
                status
                type
                themeID
            }
        }
        brands {
            results {
                id
                name
                ref
                assignedAll
                projects {
                    hasChainModule
                    name
                    chainID
                }
            }
        }
        designsLocalAssigned: designs(filter: { isAssigned: true }) {
            results {
                id
                isAssigned
                isAssignedFromCorporate
                lastUpdatedTime
                type
                themeID
            }
        }
    }
`;

export const CHAIN = gql`
    {
        chain {
            name
            ref
        }
    }
`;

export const GET_DESIGN = gql`
    query Themes($type: [Origin]) {
        data: themes(filter: { type: $type, themeType: DESIGN }) {
            results {
                id
                name
                category: type
            }
        }
    }
`;

export const CREATE_DESIGN = gql`
    mutation CreateDesign(
        $name: String!
        $type: String
        $currentDate: DateTime!
        $assignedAll: Boolean
        $themeID: Int64
        $visibleAllProjectRef: String
        $visibleAll: Boolean
        $assignedAllProjectRef: String
    ) {
        createDesign(
            name: $name
            type: $type
            currentDate: $currentDate
            assignedAll: $assignedAll
            themeID: $themeID
            visibleAllProjectRef: $visibleAllProjectRef
            visibleAll: $visibleAll
            assignedAllProjectRef: $assignedAllProjectRef
        ) {
            error
            id
            ok
        }
    }
`;

export const EDIT_DESIGN = gql`
    mutation UpdateDesign(
        $ID: Int64!
        $name: String!
        $currentDate: DateTime!
        $visibleAllProjectRef: String
        $assignedAllProjectRef: String
        $visibleAll: Boolean
        $assignedAll: Boolean
        $themeID: Int64!
    ) {
        updateDesign(
            ID: $ID
            name: $name
            currentDate: $currentDate
            visibleAllProjectRef: $visibleAllProjectRef
            assignedAllProjectRef: $assignedAllProjectRef
            visibleAll: $visibleAll
            assignedAll: $assignedAll
            themeID: $themeID
        ) {
            error
            ok
        }
    }
`;
