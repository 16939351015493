import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "components/Modal";
import classNames from "classnames";
import Modal from "components/Modal";
import Button from "components/Button";
import { Session } from "hooks/Utils/Session";
import { tmpGetChain } from "hooks/Utils/Utils";
import Dropdown from "components/Dropdown";
import Icon from "components/Icon";

export const Properties = ({ projectsInUse }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { close } = useModal();
    const brands = JSON.parse(Session.getSessionProp("brands"));

    const chevronClass = classNames({
        [`flex item-center animated cursor-pointer ${open ? "rotate-180" : ""}`]: true,
    });

    return (
        <Modal
            title={t("Properties")}
            className="p-10 w-1/3"
            footer={
                <div className="flex justify-end space-x-2">
                    <Button id="close" design="blue" onClick={close}>
                        {t("close")}
                    </Button>
                </div>
            }
        >
            <p className="py-4">{t("The following properties have access to the global design")}</p>
            <hr />
            {projectsInUse?.map((project) => {
                const projectBrand = brands?.find((brand) => brand?.ref === project?.ref);

                if (project?.type === "BRAND") {
                    return (
                        <Dropdown
                            id="properties"
                            autoClose={false}
                            onOpenChange={setOpen}
                            showArrow={false}
                            handler={
                                <div>
                                    <div className="flex items-center gap-2 py-3">
                                        <div className={chevronClass}>
                                            <Icon type="chevron" size="xl" />
                                        </div>
                                        <h1 className="font-bold">{project?.name}</h1>
                                    </div>
                                </div>
                            }
                        >
                            <div className="mt-2">
                                <hr />
                            </div>
                            {projectBrand?.projects?.map((property) => {
                                return (
                                    <div className="flex items-center gap-2 py-4 pr-4 pl-6 border-b border-gray-300">
                                        {property?.hasChainModule && <Icon type="corporate-access" size="xl" />}
                                        <h1>{property?.name}</h1>
                                    </div>
                                );
                            })}
                        </Dropdown>
                    );
                }
                if (project?.type === "PROJECT") {
                    return (
                        <div className="flex items-center gap-2 py-4 border-b border-gray-300">
                            {project?.hasChainModule && <Icon type="corporate-access" size="xl" />}
                            <h1>{project?.name}</h1>
                        </div>
                    );
                }
                if (project?.ref === "ALL_PROJECTS") {
                    return (
                        <div className="flex items-center gap-2 py-4 border-b border-gray-300">
                            {project?.hasChainModule && <Icon type="corporate-access" size="xl" />}
                            <h1>{tmpGetChain()?.name}</h1>
                        </div>
                    );
                }
            })}
        </Modal>
    );
};
