import { gql } from "apollo-boost";
import Button from "components/Button";
import Icon from "components/Icon";
import Modal, { useModal } from "components/Modal";
import ReactTooltip from "components/ReactTooltip";
import Table from "components/ZafiroTable";
import { POLLINTERVAL_30S } from "hooks/Utils/Utils";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

export const useNetworkMonitor = () => {
    const { t } = useTranslation();
    const { open } = useModal();
    const [rows, setRows] = useState([]);

    const GET_NETWORK_MONITOR_DEVICES = gql`
        {
            getNetworkMonitorDevices {
                deviceName
                ip
                mac
                type
                isCriticalDevice
                snmp {
                    authenticationPassword
                    authenticationProtocol
                    community
                    level
                    privacyPassword
                    privacyProtocol
                    version
                }
                status
                warnings {
                    date
                    level
                    message
                }
            }
        }
    `;

    const GET_ZABBIX_STATUS = gql`
        query {
            getNetworkMonitorZabbixStatus {
                isAvailable
                lastUpdate
            }
        }
    `;

    const [executeNetworkMonitorDevices, { data, loading, error }] = useLazyQuery(GET_NETWORK_MONITOR_DEVICES, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_30S,
    });

    const [getZabbixStatus, { data: statusData, loading: statusLoading }] = useLazyQuery(GET_ZABBIX_STATUS, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_30S,
    });

    useEffect(() => {
        if (data && !loading && !error) {
            const devices = modelDevices(data?.getNetworkMonitorDevices, t, open, statusData);
            setRows(devices);
        }
    }, [data]);

    return { rows, executeNetworkMonitorDevices, statusData, getZabbixStatus, statusLoading };
};

const modelDevices = (devices, t, open, statusData) => {
    const getSnmpLevelClass = (level) => {
        let snmpLevelClass,
            snmpText = "";
        switch (level) {
            case 0:
                snmpLevelClass = "bg-gray-500";
                snmpText = "never available";
                break;
            case 1:
                snmpLevelClass = "bg-green-500";
                snmpText = "available";
                break;
            case 2:
                snmpLevelClass = "bg-red-100";
                snmpText = "unavailable";
                break;
            default:
                snmpLevelClass = "";
                snmpText = "";
        }
        return { snmpLevelClass, snmpText };
    };

    const getStatusIconColor = (status) => {
        switch (status) {
            case "Offline":
                return "text-red-100";
            case "Online":
                return "text-green-500";
            case "Unknown":
                return "text-gray-500";
            default:
                return "";
        }
    };

    return devices.map((device) => {
        const { status, snmp, deviceName, ip, mac, warnings, isCriticalDevice, type } = device;
        const { snmpLevelClass, snmpText } = getSnmpLevelClass(snmp.level);
        const iconColor = getStatusIconColor(status);

        return {
            name: (
                <div alt={`${deviceName} ${mac}`}>
                    <p className="text-lg text-zafiro-900">{deviceName}</p>
                    <p className="text-sm text-gray-700">
                        {t("mac")}: {mac?.toUpperCase()}
                    </p>
                </div>
            ),
            ip,
            mac,
            type,
            status: (
                <div className="flex items-center gap-1" alt={status}>
                    <Icon type="alert" size="2xl" className={iconColor} />
                    <p className="text-lg text-zafiro-900">{t(status?.toLowerCase())}</p>
                </div>
            ),
            snmp: (
                <div
                    className={`px-2 py-1 rounded ${snmpLevelClass} text-white`}
                    alt={snmp?.version}
                    data-tip={t(snmpText)}
                    data-for="default-tooltip"
                >
                    <p>SNMPv{snmp.version}</p>
                </div>
            ),
            warnings: <Warnings warnings={warnings} name={deviceName} alt={warnings?.length} />,
            warningPerDevice: warnings,
            deviceStatus: status,
            criticalDevice: isCriticalDevice,
            critical: isCriticalDevice ? <Icon type="critical-device" size="3xl" tooltip={t("critical")} /> : null,
            rowConfig: {
                actions: [
                    {
                        id: "view-warnings",
                        label: t("view-warnings"),
                        disabled: !warnings?.length || !statusData?.getNetworkMonitorZabbixStatus?.isAvailable,
                        onClick: () => open(<ShowWarnings warnings={warnings} name={deviceName} />),
                    },
                ],
                expanded: <MonitorExtendedInfo snmp={snmp} />,
            },
        };
    });
};

const Warnings = ({ warnings, name }) => {
    const { open } = useModal();
    const { t } = useTranslation();

    const warningTypes = [
        { type: "critical", level: 3, tooltip: t("high") },
        { type: "warning", level: 2, tooltip: t("average") },
        { type: "blue-warning", level: 1, iconClass: "text-blue-100", tooltip: t("informational") },
        { type: "blue-warning", level: 0, iconClass: "text-gray-700", tooltip: t("unspecified") },
    ];

    return (
        <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => (warnings?.length ? open(<ShowWarnings warnings={warnings} name={name} />) : null)}
        >
            {warningTypes.map(({ type, level, iconClass, tooltip }) => {
                const filteredWarnings = warnings?.filter((warning) => warning.level === level);
                return filteredWarnings?.length ? (
                    <div key={level} className="flex items-center gap-1">
                        <Icon type={type} size="xl" className={iconClass} tooltip={tooltip} />
                        <p className={`text-lg`}>{filteredWarnings.length}</p>
                    </div>
                ) : null;
            })}
        </div>
    );
};

const ShowWarnings = ({ warnings, name }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const calculateDaysAgo = (date) => {
        const now = new Date();
        const warningDate = new Date(date);
        const diffInMilliseconds = now - warningDate;
        const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);

        const days = diffInDays;
        const hours = diffInHours % 24;
        const minutes = diffInMinutes % 60;

        return `${days}d ${hours}h ${minutes}m`;
    };
    return (
        <Modal
            id="show-warnings"
            title={`${name} - ${t("warnings")}`}
            footer={
                <Button id="cancel" design="blue" onClick={close}>
                    {t("cancel")}
                </Button>
            }
        >
            <Table
                id="warnings-table"
                cols={["message", "date", "duration"]}
                headers={{
                    message: { width: "40%" },
                    date: { width: "30%" },
                    duration: { width: "30%" },
                }}
                rows={warnings.map((warning) => ({
                    message: (
                        <div className="flex items-center gap-3">
                            <Icon
                                type={warning?.level === 3 ? "critical" : "warning"}
                                size="2xl"
                                className={warning.level === 1 && "text-blue-100"}
                            />
                            <p className="text-lg">{warning.message}</p>
                        </div>
                    ),
                    date: warning.date,
                    duration: calculateDaysAgo(warning.date),
                }))}
            />
        </Modal>
    );
};

const MonitorExtendedInfo = ({ snmp }) => {
    const { t } = useTranslation();

    return (
        <div className={`text-sm text-gray-800 leading-6 grid grid-cols-4 w-7/12 ml-10`}>
            {Number(snmp?.version) === 3 ? (
                <>
                    <span className="text-gray-700">
                        {t("snmp-community")}: {snmp?.community}
                    </span>
                    <div className="flex flex-col ml-2 text-gray-700">
                        <span>
                            {t("authentication-protocol")}: {snmp?.authenticationProtocol}
                        </span>
                        <span>
                            {t("privacy-protocol")}: {snmp?.privacyProtocol}
                        </span>
                    </div>
                </>
            ) : (
                <span className="text-gray-700">
                    {t("snmp-community")}: {snmp?.community}
                </span>
            )}
        </div>
    );
};
