import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import Select from "components/Select";
import { ProjectBadge } from "components/Section/Settings/PropertyGroups";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useCreateGroupProperty, useDeletePropertyGroup } from "../GroupsProperties/useListPropertyGroups";
import Loading from "components/Loading";
import Icon from "components/Icon";

const ModalDeletePropertyGroups = ({ close, refetch, names = [], ids = [], isBatch }) => {
    const { t } = useTranslation();

    const { deletePropertyGroup, loadingDeletePropertyGroup } = useDeletePropertyGroup({
        close,
        refetch,
    });

    const handleClose = () => {
        close();
    };

    const handleSave = () => {
        deletePropertyGroup({ variables: { id: ids } });
    };

    console.log(names);

    return (
        <Modal
            footer={
                !loadingDeletePropertyGroup ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        <Button design="red" id="modal-button-save" onClick={handleSave}>
                            {t("delete")}
                        </Button>
                    </>
                ) : null
            }
            className="w-4/12 p-10"
            title={isBatch ? t("delete-groups") : `${names[0]} - ${t("delete")}`}
        >
            {loadingDeletePropertyGroup ? <Loading /> : null}
            {!isBatch && !loadingDeletePropertyGroup ? (
                <div>
                    <span>{t("Are you sure you want to delete this group of properties?")}</span>
                    {/* <div className=" flex items-start mt-2">
                        <Icon size={1.5} type="warning" className=" mr-2" />
                        <div>
                            {t(
                                "Some properties are using some screens of the global design on a signage sequence. If the group is deleted, these properties will no longer have access to the design, and the screens will be automatically unliked from the sequence."
                            )}
                        </div>
                    </div> */}
                </div>
            ) : null}
            {isBatch && !loadingDeletePropertyGroup ? (
                <div>
                    <span className=" inline-block mb-2">{t("delete-properties-groups-confirm")}</span>

                    <div className=" flex flex-wrap w-full mt-2">
                        {names.map((name) => {
                            return <div className=" bg-gray-200 px-6 py-1 mr-2 mt-2 rounded">{name}</div>;
                        })}
                    </div>
                </div>
            ) : null}
        </Modal>
    );
};

export const useModalDeletePropertyGroups = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(<ModalDeletePropertyGroups {...props} close={close} />);
        },
    };
};

export default useModalDeletePropertyGroups;
