import React, { useEffect } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import { NetworkMonitorCards } from "./NetworkMonitorCards";
import Table from "components/ZafiroTable";
import { useNetworkMonitor } from "hooks/GraphqlCalls/Hotel/Monitoring/useNetworkMonitor";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";
import { ZabbixError } from "./ZabbixError";

export const NetworkMonitor = () => {
    const { t } = useTranslation();
    const { rows, executeNetworkMonitorDevices, getZabbixStatus, statusData, statusLoading } = useNetworkMonitor();
    const superUser = Session.isSuperUser();

    useEffect(() => {
        executeNetworkMonitorDevices();
        getZabbixStatus();
    }, []);

    return (
        <>
            <UseSectionHeader title={t("network-monitor")} navToPreviousPage={true} />
            <main>
                <div className="flex justify-end">
                    {superUser && (
                        <Button
                            id="network-monitor-configuration"
                            design="blue-outline"
                            className="font-bold flex items-center"
                        >
                            <Icon type="superuser" size="2xl" />
                            <p>{t("zafiro wireless gateway configuration")}</p>
                        </Button>
                    )}
                </div>
                <section className="bg-white p-6 rounded mt-4">
                    <NetworkMonitorCards monitoring={false} />
                </section>
                {!statusData?.getNetworkMonitorZabbixStatus?.isAvailable && !statusLoading && <ZabbixError />}
                <section className="bg-white p-6 rounded mt-4">
                    {rows.length ? (
                        <Table
                            id="network-monitor-table"
                            search={true}
                            paginate={true}
                            showCount={true}
                            cols={["name", "ip", "type", "status", "snmp", "warnings", "critical"]}
                            rows={rows}
                            header={{
                                name: { title: t("name"), sortable: true },
                                ip: { title: t("ip"), sortable: true },
                                type: { title: t("type"), sortable: true },
                                status: { title: t("status"), sortable: true },
                                snmp: { title: t("snmp"), sortable: true },
                                warnings: { title: t("warnings"), sortable: true },
                                critical: { title: t(""), sortable: false },
                            }}
                            filters={[
                                {
                                    id: "device-type",
                                    title: t("device-type"),
                                    multiple: true,
                                    options: [
                                        { value: "Router", label: t("Router") },
                                        { value: "Switch", label: t("Switch") },
                                        { value: "AP", label: t("AP") },
                                    ],
                                    onFilter: (value, row) => value.includes(row.type),
                                },
                                {
                                    id: "severity",
                                    title: t("severity"),
                                    multiple: true,
                                    options: [
                                        {
                                            value: 3,
                                            label: (
                                                <span className="align-middle">
                                                    <Icon type="critical" className="mr-1" size="xl" />
                                                    <span>{t("critical")}</span>
                                                </span>
                                            ),
                                        },
                                        {
                                            value: 2,
                                            label: (
                                                <span className="align-middle">
                                                    <Icon type="warning" className="mr-1" size="xl" />
                                                    <span>{t("average")}</span>
                                                </span>
                                            ),
                                        },
                                        {
                                            value: 1,
                                            label: (
                                                <span className="align-middle">
                                                    <Icon type="critical" className="text-blue-100 mr-1" size="xl" />
                                                    <span>{t("informational")}</span>
                                                </span>
                                            ),
                                        },
                                        {
                                            value: 0,
                                            label: (
                                                <span className="align-middle">
                                                    <Icon
                                                        type="blue-warning"
                                                        className="text-gray-700 mr-1"
                                                        size="xl"
                                                    />
                                                    <span>{t("not-classified")}</span>
                                                </span>
                                            ),
                                        },
                                    ],
                                    onFilter: (value, row) =>
                                        row?.warningPerDevice?.some((warning) => value.includes(warning.level)),
                                },
                                {
                                    id: "status",
                                    title: t("status"),
                                    multiple: true,
                                    options: [
                                        {
                                            value: "offline",
                                            label: (
                                                <span className="align-middle">
                                                    <Icon type="alert" className="text-red-100 mr-1" size="xl" />
                                                    <span>{t("offline")}</span>
                                                </span>
                                            ),
                                        },
                                        {
                                            value: "online",
                                            label: (
                                                <span className="align-middle">
                                                    <Icon type="alert" className="text-green-500 mr-1" size="xl" />
                                                    <span>{t("online")}</span>
                                                </span>
                                            ),
                                        },
                                        {
                                            value: "unknown",
                                            label: (
                                                <span className="align-middle">
                                                    <Icon type="alert" className="text-gray-700 mr-1" size="xl" />
                                                    <span>{t("unknown")}</span>
                                                </span>
                                            ),
                                        },
                                    ],
                                    onFilter: (value, row) => value.includes(row.deviceStatus?.toLowerCase()),
                                },
                                {
                                    id: "criticality",
                                    title: t("criticality"),
                                    options: [
                                        { value: true, label: t("critical") },
                                        { value: false, label: t("not-critical") },
                                    ],
                                    onFilter: (value, row) => value === row.criticalDevice,
                                },
                            ]}
                        />
                    ) : (
                        <Loading adjust="section-loading" style={{ height: "50vh" }} />
                    )}
                </section>
            </main>
        </>
    );
};
