import React from "react";
import { useTranslation } from "react-i18next";
import { ApplyModal } from "./Apply";
import Button from "components/Button";
import { useModal } from "components/Modal";

export const ApplyColumn = ({ design, refetch, designsLocalAssigned }) => {
    const { t } = useTranslation();
    const { open } = useModal();
    const { isAssigned, isAssignedFromCorporate, type, id, name } = design;
    const TEXT_WITHOUT_TRANSLATE =
        "Default design defined by X. The design will be applied by default when no other design is applied.";

    if (isAssigned) {
        return <div className="px-3 py-1 bg-blue-200 text-white rounded">{t("applied")}</div>;
    }

    if (isAssignedFromCorporate && type === "CORPORATE") {
        return designsLocalAssigned?.length ? (
            <p
                className="text-gray-700 cursor-help"
                data-tip={TEXT_WITHOUT_TRANSLATE}
                data-for="dangerous-html-tooltip"
            >
                {t("default-design")}
            </p>
        ) : (
            <div className="px-4 py-1 bg-blue-200 text-white rounded">{t("applied")}</div>
        );
    }

    if (type === "LOCAL") {
        return (
            <Button
                id={`apply-${id}`}
                className="t-link"
                onClick={() =>
                    open(
                        <ApplyModal name={name} id={id} refetch={refetch} designsLocalAssigned={designsLocalAssigned} />
                    )
                }
            >
                {t("apply")}
            </Button>
        );
    }

    return <p></p>;
};
